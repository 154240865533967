<template>
  <div>
    <div class="gva-search-box">
      <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
        <el-form-item>
          <el-button size="small" type="primary" icon="search" @click="onSubmit">查询</el-button>
          <el-button size="small" icon="refresh" @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="gva-table-box">
        <div class="gva-btn-list">
            <el-button size="small" type="primary" icon="plus" @click="openDialog">新增</el-button>
            <el-popover v-model:visible="deleteVisible" placement="top" width="160">
            <p>确定要删除吗？</p>
            <div style="text-align: right; margin-top: 8px;">
                <el-button size="small"  text=true @click="deleteVisible = false">取消</el-button>
                <el-button size="small" type="primary" @click="onDelete">确定</el-button>
            </div>
            <template #reference>
                <el-button icon="delete" size="small" style="margin-left: 10px;" :disabled="!multipleSelection.length">删除</el-button>
            </template>
            </el-popover>
        </div>
        <el-table
        ref="multipleTable"
        style="width: 100%"
        tooltip-effect="dark"
        :data="tableData"
        row-key="id"
        @selection-change="handleSelectionChange"
        >
        <el-table-column type="selection" width="55" />
        <el-table-column align="left" label="日期" width="180">
            <template #default="scope">{{ formatDate(scope.row.CreatedAt) }}</template>
        </el-table-column>
        <el-table-column align="left" label="uuid字段" prop="uuid" width="120" />
        <el-table-column align="left" label="电缆电流A相" prop="aCableCurrent" width="120" />
        <el-table-column align="left" label="护套环流A相" prop="aCableSheathCurrent" width="120" />
        <el-table-column align="left" label="护套感应电压A相" prop="aCableSheathVoltage" width="120" />
        <el-table-column align="left" label="接头温度A相" prop="aTemperature" width="120" />
        <el-table-column align="left" label="电缆电流B相" prop="bCableCurrent" width="120" />
        <el-table-column align="left" label="护套环流B相" prop="bCableSheathCurrent" width="120" />
        <el-table-column align="left" label="护套感应电压B相" prop="bCableSheathVoltage" width="120" />
        <el-table-column align="left" label="接头温度B相" prop="bTemperature" width="120" />
        <el-table-column align="left" label="电池电压" prop="batteryVoltage" width="120" />
        <el-table-column align="left" label="电缆电流C相" prop="cCableCurrent" width="120" />
        <el-table-column align="left" label="护套环流C相" prop="cCableSheathCurrent" width="120" />
        <el-table-column align="left" label="护套感应电压C相" prop="cCableSheathVoltage" width="120" />
        <el-table-column align="left" label="接头温度C相" prop="cTemperature" width="120" />
        <el-table-column align="left" label="门开关状态" prop="cabinetDoorStatus" width="120">
            <template #default="scope">{{ formatBoolean(scope.row.cabinetDoorStatus) }}</template>
        </el-table-column>
        <el-table-column align="left" label="振动状态" prop="cabinetShockStatus" width="120">
            <template #default="scope">{{ formatBoolean(scope.row.cabinetShockStatus) }}</template>
        </el-table-column>
        <el-table-column align="left" label="机柜烟雾状态" prop="cabinetSmokeStatus" width="120">
            <template #default="scope">{{ formatBoolean(scope.row.cabinetSmokeStatus) }}</template>
        </el-table-column>
        <el-table-column align="left" label="机柜倾斜状态" prop="cabinetTiltStatus" width="120">
            <template #default="scope">{{ formatBoolean(scope.row.cabinetTiltStatus) }}</template>
        </el-table-column>
        <el-table-column align="left" label="环境湿度" prop="humidity" width="120" />
        <el-table-column align="left" label="环境温度" prop="temperature" width="120" />
        <el-table-column align="left" label="无线信号强度" prop="wirelessSignalStrength" width="120" />
        <el-table-column align="left" label="经度" prop="longitude" width="120" />
        <el-table-column align="left" label="纬度" prop="latitude" width="120" />
        <el-table-column align="left" label="设备在线状态" prop="online" width="120">
            <template #default="scope">{{ formatBoolean(scope.row.online) }}</template>
        </el-table-column>
        <el-table-column align="left" label="数据产生的时间" prop="date" width="120" />
        <el-table-column align="left" label="按钮组">
            <template #default="scope">
            <el-button  text=true icon="edit" size="small" class="table-button" @click="updateGroundBoxMonitorDataFunc(scope.row)">变更</el-button>
            <el-button  text=true icon="delete" size="small" @click="deleteRow(scope.row)">删除</el-button>
            </template>
        </el-table-column>
        </el-table>
        <div class="gva-pagination">
            <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 30, 50, 100]"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            />
        </div>
    </div>
    <el-dialog v-model="dialogFormVisible" :before-close="closeDialog" title="弹窗操作">
      <el-form :model="formData" label-position="right" label-width="80px">
        <el-form-item label="uuid字段:">
          <el-input v-model="formData.uuid" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="电缆电流A相:">
          <el-input-number v-model="formData.aCableCurrent"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="护套环流A相:">
          <el-input-number v-model="formData.aCableSheathCurrent"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="护套感应电压A相:">
          <el-input-number v-model="formData.aCableSheathVoltage"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="接头温度A相:">
          <el-input-number v-model="formData.aTemperature"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="电缆电流B相:">
          <el-input-number v-model="formData.bCableCurrent"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="护套环流B相:">
          <el-input-number v-model="formData.bCableSheathCurrent"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="护套感应电压B相:">
          <el-input-number v-model="formData.bCableSheathVoltage"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="接头温度B相:">
          <el-input-number v-model="formData.bTemperature"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="电池电压:">
          <el-input v-model="formData.batteryVoltage" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="电缆电流C相:">
          <el-input-number v-model="formData.cCableCurrent"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="护套环流C相:">
          <el-input-number v-model="formData.cCableSheathCurrent"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="护套感应电压C相:">
          <el-input-number v-model="formData.cCableSheathVoltage"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="接头温度C相:">
          <el-input-number v-model="formData.cTemperature"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="门开关状态:">
          <el-switch v-model="formData.cabinetDoorStatus" active-color="#13ce66" inactive-color="#ff4949" active-text="是" inactive-text="否" clearable ></el-switch>
        </el-form-item>
        <el-form-item label="振动状态:">
          <el-switch v-model="formData.cabinetShockStatus" active-color="#13ce66" inactive-color="#ff4949" active-text="是" inactive-text="否" clearable ></el-switch>
        </el-form-item>
        <el-form-item label="机柜烟雾状态:">
          <el-switch v-model="formData.cabinetSmokeStatus" active-color="#13ce66" inactive-color="#ff4949" active-text="是" inactive-text="否" clearable ></el-switch>
        </el-form-item>
        <el-form-item label="机柜倾斜状态:">
          <el-switch v-model="formData.cabinetTiltStatus" active-color="#13ce66" inactive-color="#ff4949" active-text="是" inactive-text="否" clearable ></el-switch>
        </el-form-item>
        <el-form-item label="环境湿度:">
          <el-input-number v-model="formData.humidity"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="环境温度:">
          <el-input-number v-model="formData.temperature"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="无线信号强度:">
          <el-input-number v-model="formData.wirelessSignalStrength"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="经度:">
          <el-input-number v-model="formData.longitude"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="纬度:">
          <el-input-number v-model="formData.latitude"  style="width:100%" :precision="2" clearable />
        </el-form-item>
        <el-form-item label="设备在线状态:">
          <el-switch v-model="formData.online" active-color="#13ce66" inactive-color="#ff4949" active-text="是" inactive-text="否" clearable ></el-switch>
        </el-form-item>
        <el-form-item label="数据产生的时间:">
          <el-input v-model.number="formData.date" clearable placeholder="请输入" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button size="small" @click="closeDialog">取 消</el-button>
          <el-button size="small" type="primary" @click="enterDialog">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'CirculationData'
}
</script>

<script setup>
import {
  createGroundBoxMonitorData,
  deleteGroundBoxMonitorData,
  deleteGroundBoxMonitorDataByIds,
  updateGroundBoxMonitorData,
  findGroundBoxMonitorData,
  getGroundBoxMonitorDataList
} from '@/api/groundBoxMonitorData'

// 全量引入格式化工具 请按需保留
import { getDictFunc, formatDate, formatBoolean, filterDict } from '@/utils/format'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref } from 'vue'

// 自动化生成的字典（可能为空）以及字段
const formData = ref({
        uuid: '',
        aCableCurrent: 0,
        aCableSheathCurrent: 0,
        aCableSheathVoltage: 0,
        aTemperature: 0,
        bCableCurrent: 0,
        bCableSheathCurrent: 0,
        bCableSheathVoltage: 0,
        bTemperature: 0,
        batteryVoltage: '',
        cCableCurrent: 0,
        cCableSheathCurrent: 0,
        cCableSheathVoltage: 0,
        cTemperature: 0,
        cabinetDoorStatus: false,
        cabinetShockStatus: false,
        cabinetSmokeStatus: false,
        cabinetTiltStatus: false,
        humidity: 0,
        temperature: 0,
        wirelessSignalStrength: 0,
        longitude: 0,
        latitude: 0,
        online: false,
        date: 0,
        })

// =========== 表格控制部分 ===========
const page = ref(1)
const total = ref(0)
const pageSize = ref(10)
const tableData = ref([])
const searchInfo = ref({})

// 重置
const onReset = () => {
  searchInfo.value = {}
}

// 搜索
const onSubmit = () => {
  page.value = 1
  pageSize.value = 10
  if (searchInfo.value.cabinetDoorStatus === ""){
      searchInfo.value.cabinetDoorStatus=null
  }
  if (searchInfo.value.cabinetShockStatus === ""){
      searchInfo.value.cabinetShockStatus=null
  }
  if (searchInfo.value.cabinetSmokeStatus === ""){
      searchInfo.value.cabinetSmokeStatus=null
  }
  if (searchInfo.value.cabinetTiltStatus === ""){
      searchInfo.value.cabinetTiltStatus=null
  }
  if (searchInfo.value.online === ""){
      searchInfo.value.online=null
  }
  getTableData()
}

// 分页
const handleSizeChange = (val) => {
  pageSize.value = val
  getTableData()
}

// 修改页面容量
const handleCurrentChange = (val) => {
  page.value = val
  getTableData()
}

// 查询
const getTableData = async () => {
  //const table = await getDevModelsList({ page: page.value, pageSize: pageSize.value, ...searchInfo.value })
  const table = await service({
    url: '/api/v2/device/getCirculationDataByPage',
   // url: "/api/v2/model/query",
    method: "post",
    data:{ page: page.value, pageSize: pageSize.value, ...searchInfo.value }
    //data: {},
  })
    .then((res) => {
      if (res.code === 0) {
        if (res.data.list) {
          tableData.value = res.data.list;
          total.value = res.data.total;
          page.value = res.data.page;
          pageSize.value = res.data.pageSize;
        } else {
          tableData.value = res.data;
          total.value = res.data.length;
        }
      }
    })
    .catch((e) => {
      console.log(e);
    });
};
getTableData()

// ============== 表格控制部分结束 ===============

// 获取需要的字典 可能为空 按需保留
const setOptions = async () =>{
}

// 获取需要的字典 可能为空 按需保留
setOptions()


// 多选数据
const multipleSelection = ref([])
// 多选
const handleSelectionChange = (val) => {
    multipleSelection.value = val
}

// 删除行
const deleteRow = (row) => {
    ElMessageBox.confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
            deleteGroundBoxMonitorDataFunc(row)
        })
    }


// 批量删除控制标记
const deleteVisible = ref(false)

// 多选删除
const onDelete = async() => {
      const ids = []
      if (multipleSelection.value.length === 0) {
        ElMessage({
          type: 'warning',
          message: '请选择要删除的数据'
        })
        return
      }
      multipleSelection.value &&
        multipleSelection.value.map(item => {
          ids.push(item.id)
        })
      const res = await deleteGroundBoxMonitorDataByIds({ ids })
      if (res.code === 0) {
        ElMessage({
          type: 'success',
          message: '删除成功'
        })
        if (tableData.value.length === ids.length && page.value > 1) {
          page.value--
        }
        deleteVisible.value = false
        getTableData()
      }
    }

// 行为控制标记（弹窗内部需要增还是改）
const type = ref('')

// 更新行
const updateGroundBoxMonitorDataFunc = async(row) => {
    const res = await findGroundBoxMonitorData({ id: row.id })
    type.value = 'update'
    if (res.code === 0) {
        formData.value = res.data.redevGroundBoxMonitorData
        dialogFormVisible.value = true
    }
}


// 删除行
const deleteGroundBoxMonitorDataFunc = async (row) => {
    const res = await deleteGroundBoxMonitorData({ id: row.id })
    if (res.code === 0) {
        ElMessage({
                type: 'success',
                message: '删除成功'
            })
            if (tableData.value.length === 1 && page.value > 1) {
            page.value--
        }
        getTableData()
    }
}

// 弹窗控制标记
const dialogFormVisible = ref(false)

// 打开弹窗
const openDialog = () => {
    type.value = 'create'
    dialogFormVisible.value = true
}

// 关闭弹窗
const closeDialog = () => {
    dialogFormVisible.value = false
    formData.value = {
        uuid: '',
        aCableCurrent: 0,
        aCableSheathCurrent: 0,
        aCableSheathVoltage: 0,
        aTemperature: 0,
        bCableCurrent: 0,
        bCableSheathCurrent: 0,
        bCableSheathVoltage: 0,
        bTemperature: 0,
        batteryVoltage: '',
        cCableCurrent: 0,
        cCableSheathCurrent: 0,
        cCableSheathVoltage: 0,
        cTemperature: 0,
        cabinetDoorStatus: false,
        cabinetShockStatus: false,
        cabinetSmokeStatus: false,
        cabinetTiltStatus: false,
        humidity: 0,
        temperature: 0,
        wirelessSignalStrength: 0,
        longitude: 0,
        latitude: 0,
        online: false,
        date: 0,
        }
}
// 弹窗确定
const enterDialog = async () => {
      let res
      switch (type.value) {
        case 'create':
          //res = await createGroundBoxMonitorData(formData.value)
           res = await service({
        //url: '/api/v2/model/getByPage',
        url: "/api/v2/device/updateCirculationData",
        method: "post",
        data: {
          model: formData.value.model,
          name: formData.value.modelName,
          device_type: formData.value.deviceType, // 0: 普通设备, 1 接地箱设备
        },
      }).catch((e) => {
        console.log(e);
      });

          break
        case 'update':
          res = await updateGroundBoxMonitorData(formData.value)
          break
        default:
          res = await createGroundBoxMonitorData(formData.value)
          break
      }
      if (res.code === 0) {
        ElMessage({
          type: 'success',
          message: '创建/更改成功'
        })
        closeDialog()
        getTableData()
      }
}
</script>

<style>
</style>
