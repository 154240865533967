import service from '@/utils/request'

// @Tags GroundBoxMonitorData
// @Summary 创建GroundBoxMonitorData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.GroundBoxMonitorData true "创建GroundBoxMonitorData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /devGroundBoxMonitorData/createGroundBoxMonitorData [post]
export const createGroundBoxMonitorData = (data) => {
  return service({
    url: '/devGroundBoxMonitorData/createGroundBoxMonitorData',
    method: 'post',
    data
  })
}

// @Tags GroundBoxMonitorData
// @Summary 删除GroundBoxMonitorData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.GroundBoxMonitorData true "删除GroundBoxMonitorData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /devGroundBoxMonitorData/deleteGroundBoxMonitorData [delete]
export const deleteGroundBoxMonitorData = (data) => {
  return service({
    url: '/devGroundBoxMonitorData/deleteGroundBoxMonitorData',
    method: 'delete',
    data
  })
}

// @Tags GroundBoxMonitorData
// @Summary 删除GroundBoxMonitorData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除GroundBoxMonitorData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /devGroundBoxMonitorData/deleteGroundBoxMonitorData [delete]
export const deleteGroundBoxMonitorDataByIds = (data) => {
  return service({
    url: '/devGroundBoxMonitorData/deleteGroundBoxMonitorDataByIds',
    method: 'delete',
    data
  })
}

// @Tags GroundBoxMonitorData
// @Summary 更新GroundBoxMonitorData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.GroundBoxMonitorData true "更新GroundBoxMonitorData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /devGroundBoxMonitorData/updateGroundBoxMonitorData [put]
export const updateGroundBoxMonitorData = (data) => {
  return service({
    url: '/devGroundBoxMonitorData/updateGroundBoxMonitorData',
    method: 'put',
    data
  })
}

// @Tags GroundBoxMonitorData
// @Summary 用id查询GroundBoxMonitorData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query model.GroundBoxMonitorData true "用id查询GroundBoxMonitorData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /devGroundBoxMonitorData/findGroundBoxMonitorData [get]
export const findGroundBoxMonitorData = (params) => {
  return service({
    url: '/devGroundBoxMonitorData/findGroundBoxMonitorData',
    method: 'get',
    params
  })
}

// @Tags GroundBoxMonitorData
// @Summary 分页获取GroundBoxMonitorData列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query request.PageInfo true "分页获取GroundBoxMonitorData列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /devGroundBoxMonitorData/getGroundBoxMonitorDataList [get]
export const getGroundBoxMonitorDataList = (params) => {
  return service({
    url: '/devGroundBoxMonitorData/getGroundBoxMonitorDataList',
    method: 'get',
    params
  })
}
